'use client';
import { faUserCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UserProfile } from '@pickleballinc/ui/components/pickleball-app/UserProfile';
import CustomLink from '@pickleballinc/ui/components/ui/custom-link';
import { User } from 'protos/model';
import { useEffect, useState } from 'react';

interface WPRHeaderProfileProps {
	userData?: User;
	pickleballHref: string;
	ssoLink: string;
	canAccessManage: boolean;
}

const WPRHeaderProfile = ({ userData, pickleballHref, ssoLink, canAccessManage }: WPRHeaderProfileProps) => {
	const [baseURL, setBaseURL] = useState('');
	const isLocalHost = `${process.env.NEXT_PUBLIC_REWRITE_DOMAIN_NAME}`.startsWith('localhost');

	useEffect(() => {
		setBaseURL(window.location.href);
	}, []);

	return (
		<div className="flex h-full gap-x-8 pr-3">
			<div className="hidden h-full w-0.5 -skew-x-12 bg-experiment sm:flex" />
			{userData?.uuid ? (
				<>
					<UserProfile
						pickleballHref={pickleballHref}
						ssoLink={ssoLink}
						user={userData}
						canAccessManage={canAccessManage}
						isLocalhost={isLocalHost}
					/>
				</>
			) : isLocalHost ? (
				<CustomLink
					href={`http://${process.env.NEXT_PUBLIC_REWRITE_DOMAIN_NAME}/login?continue=${encodeURIComponent(baseURL)}`}
					className="mr-2 flex items-center gap-2 !bg-transparent !py-1.5 text-sm font-medium !leading-none text-white hover:!bg-transparent"
					rel="nofollow"
					data-testid={`pre-header-user-profile-login`}
				>
					<FontAwesomeIcon icon={faUserCircle} size="sm" className="text-base" />
					Log In
				</CustomLink>
			) : (
				<a
					href={`${ssoLink}/?flow=SelfService&continue=${encodeURIComponent(baseURL)}`}
					className="mr-2 flex items-center gap-2 !bg-transparent !py-1.5 text-sm font-medium !leading-none text-white hover:!bg-transparent"
					rel="nofollow"
					data-testid={`pre-header-user-profile-login`}
				>
					<FontAwesomeIcon icon={faUserCircle} size="sm" className="text-base" />
					Log In
				</a>
			)}
		</div>
	);
};

export default WPRHeaderProfile;
