import type { Match } from '@pickleballinc/react-ui/types/Match';
import getCDNURL from '@pickleballinc/ui/components/pickleball-app/utils/getCDNURL';
import moment from 'moment';

import type { MatchInfoV2 } from '@/modules/ticker/types';

// Featured court titles are "cc", "incc", "gs", "gs1", "gs2", "s", "s1", "s2", "sc", "sc1", "sc2" etc.
const checkIfFeaturedCourt = (courtTitle?: string) => {
	if (!courtTitle) {
		return false;
	}
	const courtTitleInLowerCaseTrimmed = courtTitle.toLowerCase().trim();
	return (
		courtTitleInLowerCaseTrimmed === 'cc' ||
		courtTitleInLowerCaseTrimmed === 'incc' ||
		courtTitleInLowerCaseTrimmed === 'gs' ||
		(courtTitleInLowerCaseTrimmed.startsWith('gs') && /^\d/.test(courtTitleInLowerCaseTrimmed.charAt(2))) ||
		courtTitleInLowerCaseTrimmed === 's' ||
		(courtTitleInLowerCaseTrimmed.startsWith('s') && /^\d/.test(courtTitleInLowerCaseTrimmed.charAt(1))) ||
		courtTitleInLowerCaseTrimmed === 'sc' ||
		(courtTitleInLowerCaseTrimmed.startsWith('sc') && /^\d/.test(courtTitleInLowerCaseTrimmed.charAt(2)))
	);
};

const getMatchCourtLabel = (courtTitle?: string) => {
	if (!courtTitle) {
		return '';
	}
	const courtTitleInLowerCaseTrimmed = courtTitle.toLowerCase().trim();
	return courtTitleInLowerCaseTrimmed === 'incc' ? 'CC' : courtTitle;
};

const getFormattedRoundText = (roundText?: string) => {
	if (!roundText) {
		return '';
	}

	if (roundText.startsWith('Round')) {
		const roundTextSplit = roundText.split(' ');
		let formattedRoundText = '';

		if (roundTextSplit[1] && roundTextSplit[1] === 'of') {
			return roundText;
		}

		if (roundTextSplit[0]) {
			formattedRoundText += roundTextSplit[0];
		}

		if (roundTextSplit[1]) {
			formattedRoundText += ' of ';
			formattedRoundText += roundTextSplit[1];
		}

		return formattedRoundText;
	}

	return roundText;
};

export interface FormatMatchInfoProps {
	matchInfos: MatchInfoV2[];
	imagesWidth: number;
	imagesHeight: number;
}

export function formatTickerMatches({ matchInfos, imagesWidth, imagesHeight }: FormatMatchInfoProps) {
	if (matchInfos.length === 0) return [];

	const matches: Match[] = matchInfos.map((matchInfo) => {
		const teamOneScores = [matchInfo.teamOneGameOneScore];
		const teamTwoScores = [matchInfo.teamTwoGameOneScore];
		const gamesStatus: number[] = [+matchInfo.gameOneStatus];

		const teamOnePlayers = [
			{
				playerId: matchInfo.teamOnePlayerOneUuid,
				firstName: matchInfo.teamOnePlayerOneFirstName,
				lastName: matchInfo.teamOnePlayerOneLastName,
				suffixName: matchInfo.teamOnePlayerOneNameSuffix,
				image: getCDNURL(matchInfo.teamOnePlayerOnePicture, imagesWidth, imagesHeight)
			}
		];
		const teamTwoPlayers = [
			{
				playerId: matchInfo.teamTwoPlayerOneUuid,
				firstName: matchInfo.teamTwoPlayerOneFirstName,
				lastName: matchInfo.teamTwoPlayerOneLastName,
				suffixName: matchInfo.teamTwoPlayerOneNameSuffix,
				image: getCDNURL(matchInfo.teamTwoPlayerOnePicture, imagesWidth, imagesHeight)
			}
		];

		if (matchInfo.teamOnePlayerTwoUuid) {
			teamOnePlayers.push({
				playerId: matchInfo.teamOnePlayerTwoUuid,
				firstName: matchInfo.teamOnePlayerTwoFirstName,
				lastName: matchInfo.teamOnePlayerTwoLastName,
				suffixName: matchInfo.teamOnePlayerTwoNameSuffix,
				image: getCDNURL(matchInfo.teamOnePlayerTwoPicture, imagesWidth, imagesHeight)
			});
		}

		if (matchInfo.teamTwoPlayerTwoUuid) {
			teamTwoPlayers.push({
				playerId: matchInfo.teamTwoPlayerTwoUuid,
				firstName: matchInfo.teamTwoPlayerTwoFirstName,
				lastName: matchInfo.teamTwoPlayerTwoLastName,
				suffixName: matchInfo.teamTwoPlayerTwoNameSuffix,
				image: getCDNURL(matchInfo.teamTwoPlayerTwoPicture, imagesWidth, imagesHeight)
			});
		}

		if (matchInfo.scoreFormatGameBestOutOf > 1) {
			teamOneScores.push(matchInfo.teamOneGameTwoScore);
			teamTwoScores.push(matchInfo.teamTwoGameTwoScore);
			gamesStatus.push(+matchInfo.gameTwoStatus);
		}

		if (matchInfo.scoreFormatGameBestOutOf > 2) {
			teamOneScores.push(matchInfo.teamOneGameThreeScore);
			teamTwoScores.push(matchInfo.teamTwoGameThreeScore);
			gamesStatus.push(+matchInfo.gameThreeStatus);
		}

		if (matchInfo.scoreFormatGameBestOutOf > 3) {
			teamOneScores.push(matchInfo.teamOneGameFourScore);
			teamTwoScores.push(matchInfo.teamTwoGameFourScore);
			gamesStatus.push(+matchInfo.gameFourStatus);
		}

		if (matchInfo.scoreFormatGameBestOutOf > 4) {
			teamOneScores.push(matchInfo.teamOneGameFiveScore);
			teamTwoScores.push(matchInfo.teamTwoGameFiveScore);
			gamesStatus.push(+matchInfo.gameFiveStatus);
		}

		const formattedRoundText = getFormattedRoundText(matchInfo.roundText);

		const streamingService = matchInfo.streamingServices[0] || undefined;

		const saturation = matchInfo.matchStatus === 4 ? -100 : 0;

		const matchObject: Match = {
			id: matchInfo.matchUuid,
			timezoneAbbreviation: matchInfo.timezoneAbbreviation,
			matchStatus: matchInfo.matchStatus,
			matchCompletedType: matchInfo.matchCompletedType,
			currentServingNumber: matchInfo.currentServingNumber,
			server: matchInfo.server,
			serverFromTeam: matchInfo.serverFromTeam,
			team1: {
				players: [...teamOnePlayers],
				//percentage: Math.round(matchInfo.teamOneWinningPercentage * 10) / 10,
				scores: [...teamOneScores],
				isWinner: matchInfo.matchStatus === 4 && matchInfo.winner === 1
			},
			team2: {
				players: [...teamTwoPlayers],
				//percentage: Math.round((100 - matchInfo.teamOneWinningPercentage) * 10) / 10,
				scores: [...teamTwoScores],
				isWinner: matchInfo.matchStatus === 4 && matchInfo.winner === 2
			},
			gamesStatus: gamesStatus,
			roundNumber: formattedRoundText || (matchInfo.roundNumber ? `Round ${matchInfo.roundNumber}` : ''),
			courtName: checkIfFeaturedCourt(matchInfo.courtTitle) ? getMatchCourtLabel(matchInfo.courtTitle) : '',
			tournamentTitle: matchInfo.tournamentTitle,
			matchTimeStart: moment
				.utc(matchInfo?.localDateMatchCompleted || matchInfo?.localDateMatchStart || matchInfo?.localDateMatchPlannedStart)
				.format('h:mm A'),
			links: {
				matchStatsURL: `/results/match/${matchInfo.matchUuid}`,
				liveURL: matchInfo.matchStatus === 2 && streamingService?.liveUrl ? streamingService.liveUrl : undefined,
				archivedURL: matchInfo.matchStatus === 4 && streamingService?.archivedUrl ? streamingService.archivedUrl : undefined,
				highlightsURL: matchInfo.matchStatus === 4 && streamingService?.highlightsUrl ? streamingService.highlightsUrl : undefined,
				liveURLTarget: '_blank',
				archivedURLTarget: '_blank',
				highlightsURLTarget: '_blank'
			},
			sponsors: streamingService
				? {
						logoUrl: getCDNURL(streamingService.logoUrl, 100, 100, saturation),
						serviceId: streamingService.serviceId,
						serviceName: streamingService.serviceName
					}
				: undefined
		};

		return { ...matchObject, changeHappened: matchInfo.changeHappened };
	});

	return matches;
}
