'use client';

import React from 'react';

interface ModalSSOProps {
	iframeParentBaseURL: string;
}

export const ModalSSO = ({ iframeParentBaseURL }: ModalSSOProps) => {
	const ssoURL = `${process.env.NEXT_PUBLIC_SSO_ENDPOINT}?emb=1&continue=${encodeURI(iframeParentBaseURL)}`;

	console.log('Iframe src:', ssoURL);

	return <iframe src={ssoURL} width="100%" height="100%" />;
};
