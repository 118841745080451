import moment from 'moment';

export const TickerSummary = () => {
	const date = moment();

	return (
		<div className="flex w-16 shrink-0 flex-col divide-y divide-gray-300 border-x border-gray-300 bg-white sm:w-28">
			<div className="flex flex-1 flex-col items-center justify-center py-2 text-center">
				<span className="text-xs uppercase leading-5 tracking-wider text-gray-600">{date.format('MMM')}</span>
				<span className="text-xl font-bold uppercase tracking-wider text-black">{date.format('DD')}</span>
			</div>
		</div>
	);
};
