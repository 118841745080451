'use client';

import TopNavigation, { TopNavigationProps } from '@pickleballinc/ui/components/pickleball-app/TopNavigation';
import React from 'react';

import { useModalSSO } from '@/lib/modal-sso';

export const TopNavigationWrapper = (props: TopNavigationProps) => {
	const { setActive } = useModalSSO();

	return <TopNavigation {...props} onSSOClick={process.env.NEXT_PUBLIC_APP_ENV === 'prod' ? undefined : () => setActive(true)} />;
};
