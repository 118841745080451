const ADBUTLER_URL = 'https://servedbyadbutler.com/adserve/';
const ADBUTLER_ID = '186107';
const ADVERTISEMENT_TEXT_PLACEHOLDER = 'ADVERTISEMENT';

const AD_SIZES_HASH = {
	'679331': {
		size: '300x250',
		width: 300,
		height: 250
	}, // small card ad
	'679332': {
		size: '300x600',
		width: 300,
		height: 600
	}, // long card ad
	'679340': {
		size: '1280x130',
		width: 1280,
		height: 130
	}, // wide banner ad
	'688399': {
		size: '300x250',
		width: 300,
		height: 250
	}, // tourney card ad
	'688401': {
		size: '300x250',
		width: 300,
		height: 250
	}, // league card ad
	'721101': {
		size: '0x0',
		width: 0,
		height: 0
	}, // modal ad
	'788107': {
		size: '0x0',
		width: 0,
		height: 0
	}, // tournament search ad
	'809175': {
		size: '0x0',
		width: 0,
		height: 0
	} // modal ad redesigned
};

const ZONE_IDS = {
	SMALL_CARD: '679331',
	LONG_CARD: '679332',
	BANNER: '679340',
	TOURNEY_CARD: '688399',
	LEAGUE_CARD: '688401',
	MODAL: '721101',
	TOURNEY_SEARCH: '788107',
	MODAL_REDESIGNED: '809175'
};

export { ADBUTLER_URL, ADBUTLER_ID, ADVERTISEMENT_TEXT_PLACEHOLDER, AD_SIZES_HASH, ZONE_IDS };
