import React, { AnchorHTMLAttributes } from 'react';
import Link, { LinkProps } from 'next/link';

interface CustomLinkProps extends LinkProps, Omit<AnchorHTMLAttributes<HTMLAnchorElement>, keyof LinkProps> {
	children?: React.ReactNode;
}

const CustomLink = ({ prefetch = false, ...props }: CustomLinkProps) => {
	return (
		<Link {...props} prefetch={prefetch}>
			{props.children}
		</Link>
	);
};

export default CustomLink;
