'use client';
import { createContext, useContext, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { updateImpressions } from '@/components/ImpressionWrapper/impressionHooks';
import { SessionData } from '@/lib/getSessionPages';
import { ImpressionContextInterface, ImpressionProviderProps } from '@/types/impressions.types';
import { USER_IMPRESSION_KEY } from '@/utils/helpers/constants';

const ImpressionContext = createContext<ImpressionContextInterface | undefined>(undefined);

export const ImpressionProvider = ({ children, session }: ImpressionProviderProps) => {
	// TODO: When migrating to the new App Router, remove this cast '(session as UserSession)' and update the type definition for the 'session' prop in the ImpressionProviderProps interface
	const sessionUUID = (session as SessionData)?.user?.uuid;
	// Initialize userId state, which will be used to send the correct user ID to the API
	const [userId, setUserId] = useState<string>(sessionUUID || '');
	const isLocalhost = process.env.NEXT_PUBLIC_REWRITE_DOMAIN_NAME?.startsWith('localhost');

	useEffect(() => {
		// Only track impressions in production or development
		if (isLocalhost) {
			return;
		}
		const handleImpressions = async () => {
			// Retrieve the temporary user ID from local storage, if it exists
			const storedUserId = localStorage.getItem(USER_IMPRESSION_KEY);

			// User is logged in (sessionUUID exists)
			if (sessionUUID) {
				// If there is a stored temporary user ID, update impressions
				if (storedUserId) {
					try {
						// Send a PATCH request to update impressions, BE handle stored impressions with the logged-in user
						const response = await updateImpressions(storedUserId);

						// If successful, remove the temporary ID from local storage
						if (response.statusCode === 200) {
							localStorage.removeItem(USER_IMPRESSION_KEY);
						}
					} catch (error) {
						console.error('Failed to update impressions:', error);
					}
				}
				// When a user logs in, set the user ID to the session's UUID and use it in the API for impressions
				setUserId(sessionUUID);
			} else {
				// User is not logged in (no sessionUUID) and If a temporary user ID exists in local storage, use it
				if (storedUserId) {
					setUserId(storedUserId);
				} else {
					// If no temporary ID exists, generate a new one and store it
					const newUserId = uuidv4();
					localStorage.setItem(USER_IMPRESSION_KEY, newUserId);
					setUserId(newUserId);
				}
			}
		};

		handleImpressions();
	});

	return <ImpressionContext.Provider value={{ userId }}>{children}</ImpressionContext.Provider>;
};

export const useImpression = () => {
	const context = useContext(ImpressionContext);
	if (!context) {
		throw new Error('useImpression must be used within an ImpressionProvider');
	}
	return context;
};
