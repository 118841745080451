'use client';

import '@/styles/global.css';

import { ErrorPage } from '@pickleballinc/features/src/error-pages/error-page';
import getCDNURL from '@pickleballinc/ui/components/pickleball-app/utils/getCDNURL';
import { Button } from '@pickleballinc/ui/components/ui/button';
import CustomLink from '@pickleballinc/ui/components/ui/custom-link';
import type { Metadata } from 'next';
import { Inter } from 'next/font/google';

import { BackButton } from '@/components/Error/back-button';

const inter = Inter({ subsets: ['latin'] });

export default function GlobalError() {
	return (
		<html lang="en">
			<head>
				<meta name="COPYRIGHT" content={`(c) 2015-${new Date().getFullYear()} Pickleball OpCo LLC`} />
				<link rel="icon" href={getCDNURL(`${process.env.NEXT_PUBLIC_IMAGE_CDN}/pickleball-app/favicon-32x32.png`, 32, 32)} />
			</head>
			<body className={`bg-gray-50 ${inter.className}`} id="body">
				<div className="flex size-full flex-1 flex-col text-gray-700 antialiased">
					<main className="h-full bg-gray-100 py-4 sm:py-6">
						<ErrorPage title="Page out of bounds." description="We couldn’t find the page you were looking for.">
							<BackButton />
							<CustomLink href="/">
								<Button variant="default" className="w-full bg-experiment text-white hover:bg-experiment/80 md:w-32">
									Back to home
								</Button>
							</CustomLink>
						</ErrorPage>
					</main>
				</div>
			</body>
		</html>
	);
}

export const metadata: Metadata = {
	metadataBase: new URL(`https://${process.env.NEXT_PUBLIC_REWRITE_DOMAIN_NAME}`),
	title: 'Pickleball.com - All Things Pickleball.',
	description:
		'Pickleball.com is the place for live scores, latest news, video highlights, merchandise, tournaments, leagues, clubs, ratings, and clinics',
	keywords:
		'Pickleball News, Pro Pickleball, Tournament Site, Bracket Generator, Pickleball Tournaments, Pickleball Clinics, Pickleball League, Pickleball Brackets, Elimination Bracket, Round Robin, Pool Play, Blind Draw, Singles, Doubles, Mix Doubles, Mens, Womens, Teams, Pickleball, Pickleball Paddle, Paddle, Pickleball Registrations, USAP, Pickleball Canada',
	openGraph: {
		siteName: 'Pickleball.com',
		url: `https://${process.env.NEXT_PUBLIC_REWRITE_DOMAIN_NAME}`,
		title: 'Pickleball.com | Official Site for all things Pickleball',
		description:
			'Pickleball.com is the place for live scores, latest news, video highlights, merchandise, tournaments, leagues, clubs, ratings, and clinics',
		images: [
			{
				url: getCDNURL(`${process.env.NEXT_PUBLIC_IMAGE_CDN}/pickleball-app/pickleball.png`, 1200, 630)
			}
		],
		type: 'website'
	},
	twitter: {
		card: 'summary_large_image',
		site: 'pickleball',
		title: 'Pickleball.com | Official Site for all things Pickleball',
		description:
			'Pickleball.com is the place for live scores, latest news, video highlights, merchandise, tournaments, leagues, clubs, ratings, and clinics',
		images: [
			{
				url: getCDNURL(`${process.env.NEXT_PUBLIC_IMAGE_CDN}/pickleball-app/pickleball.png`, 1200, 630)
			}
		]
	},
	icons: [
		{
			rel: 'apple-touch-icon',
			url: getCDNURL(`${process.env.NEXT_PUBLIC_IMAGE_CDN}/pickleball-app/apple-touch-icon.png`, 180, 180)
		},
		{
			rel: 'icon',
			type: 'image/png',
			sizes: '32x32',
			url: getCDNURL(`${process.env.NEXT_PUBLIC_IMAGE_CDN}/pickleball-app/favicon-32x32.png`, 32, 32)
		},
		{
			rel: 'icon',
			type: 'image/png',
			sizes: '16x16',
			url: getCDNURL(`${process.env.NEXT_PUBLIC_IMAGE_CDN}/pickleball-app/favicon-16x16.png`, 16, 16)
		},
		{
			rel: 'icon',
			url: getCDNURL(`${process.env.NEXT_PUBLIC_IMAGE_CDN}/pickleball-app/favicon.ico`, 512, 512)
		},
		{
			rel: 'icon',
			type: 'image/png',
			sizes: '192x192',
			url: getCDNURL(`${process.env.NEXT_PUBLIC_IMAGE_CDN}/pickleball-app/android-chrome-192x192.png`, 192, 192)
		},
		{
			rel: 'icon',
			type: 'image/png',
			sizes: '512x512',
			url: getCDNURL(`${process.env.NEXT_PUBLIC_IMAGE_CDN}/pickleball-app/android-chrome-512x512.png`, 512, 512)
		}
	],
	alternates: {
		canonical: `https://${process.env.NEXT_PUBLIC_REWRITE_DOMAIN_NAME}`
	}
};
