'use client';

import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';

import { ModalSSODialog } from '@/lib/modal-sso/ModalSSODialog';

interface ModalSSOState {
	active?: boolean;
}

const initialState = {
	active: false
};

interface ModalSSOContextInterface extends ModalSSOState {
	setActive: (active: boolean) => void;
}

const stub = (): never => {
	throw new Error('You forgot to wrap your component in <ModalSSOProvider>.');
};

const initialContext = {
	...initialState,
	setActive: stub
};

const ModalSSOContext = createContext<ModalSSOContextInterface>(initialContext);

export const useModalSSO = (context = ModalSSOContext) => useContext(context) as ModalSSOContextInterface;

interface ModalSSOProviderProps {
	children: React.ReactNode;
}

export const ModalSSOProvider = ({ children }: ModalSSOProviderProps) => {
	const [open, setOpen] = useState(false);

	const setActive = useCallback((active: boolean) => {
		setOpen(active);
	}, []);

	const contextValue = useMemo(() => {
		return { active: open, setActive };
	}, [open, setActive]);

	return (
		<ModalSSOContext.Provider value={contextValue}>
			{children}
			<ModalSSODialog />
		</ModalSSOContext.Provider>
	);
};
