interface TriggerURLResponse {
	status: number;
}

// This function is called on client only
export async function triggerURL(URL: string): Promise<TriggerURLResponse> {
	try {
		const response = await fetch(URL, {
			method: 'GET',
			mode: 'no-cors'
		});

		if (response.ok) {
			return {
				status: 200
			};
		}

		return {
			status: response.status
		};
	} catch (e) {
		return {
			status: 404
		};
	}
}
