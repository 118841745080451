export interface RenderIframeProps {
	className: string;
	title?: string;
	src: string;
}

const RenderIframe = ({ className, title, src }: RenderIframeProps) => {
	return <iframe className={className} title={title} src={src} allowFullScreen />;
};

export default RenderIframe;
