'use client';

import { faBars, faTimes } from '@fortawesome/pro-light-svg-icons';
import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Navbar, NavbarItem } from '@pickleballinc/react-ui';
import { Menu } from '@pickleballinc/react-ui';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import GlobalSearch, { GlobalSearchButtonsResponse } from './GlobalSearch';
import { Nav } from './Nav';
import NotificationsPopupMobile from './NotificationsPopupMobile';
import { UserProfile } from './UserProfile';
import { BLOG_LABEL } from './utils/constants';
import getCDNURL from './utils/getCDNURL';
import { Application, getNavItems } from './utils/nav.config';
import { NewsCategoryShortInterface, NotificationLookup, User } from './utils/types';

interface MainHeaderProps {
	user: User | undefined;
	searchButtons: GlobalSearchButtonsResponse;
	application: Application;
	categories: NewsCategoryShortInterface[];
	notifications: NotificationLookup | undefined;
	canAccessManage: boolean;
	pickleballHref: string;
	tournamentsHref: string;
	clubsHref: string;
	teamLeaguesHref?: string;
	leaguesHref: string;
	ssoLink: string;
	checkoutLink: string;
	cdnUrl: string;
	showTLItem?: boolean;
}

const validBlogPathname = /^\/[a-zA-Z0-9-]+\/[a-zA-Z0-9-]+$/;

export default function MainHeader({
	user,
	searchButtons,
	application,
	categories,
	notifications,
	canAccessManage,
	pickleballHref,
	tournamentsHref,
	clubsHref,
	teamLeaguesHref = '',
	leaguesHref,
	ssoLink,
	checkoutLink,
	cdnUrl,
	showTLItem
}: MainHeaderProps) {
	const [searchShown, setSearchShown] = useState(false);
	const pathname = usePathname();
	const [mobileMenuOpened, setMobileMenuOpened] = React.useState(false);
	const searchRef = useRef(null);
	const [baseURL, setBaseURL] = useState('');

	useEffect(() => {
		setBaseURL(window.location.href);
	}, []);

	const onRouteChangeStart = () => {
		setMobileMenuOpened(false);
	};

	useEffect(() => {
		onRouteChangeStart();
	}, [pathname]);

	useEffect(() => {
		if (mobileMenuOpened) {
			document.body.style.overflow = 'hidden';
		} else {
			document.body.style.removeProperty('overflow');
		}
	}, [mobileMenuOpened]);

	const isPickleball = application === 'pickleball';
	const isTournament = application === 'tournament';
	const isClubs = application === 'clubs';
	const isTeamLeagues = application === 'team-leagues';

	const isLocalHost = isPickleball
		? pickleballHref?.includes('localhost')
		: isClubs
			? clubsHref?.includes('localhost')
			: isTeamLeagues
				? teamLeaguesHref?.includes('localhost')
				: isTournament
					? tournamentsHref?.includes('localhost')
					: false;

	const pickleballPrefix = `http${isPickleball && isLocalHost ? '' : 's'}://`;

	const baseOrigin = isLocalHost
		? ''
		: baseURL.includes(`${leaguesHref}`)
			? `${leaguesHref}`
			: baseURL.includes(`${clubsHref}`)
				? `${clubsHref}`
				: baseURL.includes(`${teamLeaguesHref}`)
					? `${teamLeaguesHref}`
					: baseURL.includes(`${tournamentsHref}`)
						? `${tournamentsHref}`
						: `https://${pickleballHref}`;

	const filteredCategories = categories ? categories.filter((category) => category.slug !== 'news') : [];
	const pathSegments = pathname?.split('/').filter((segment) => segment) || [];
	const categorySlug = pathSegments[0];
	const isBlogPathname = pathname && validBlogPathname.test(pathname) && filteredCategories.some((category) => category.slug === categorySlug);

	const navItems = useMemo(
		() =>
			getNavItems({
				isLocalHost,
				application,
				pickleballHref,
				tournamentsHref,
				clubsHref,
				teamLeaguesHref: showTLItem ? teamLeaguesHref : undefined,
				leaguesHref
			}),
		[isLocalHost, application, pickleballHref, tournamentsHref, clubsHref, teamLeaguesHref, leaguesHref, showTLItem]
	);

	return (
		<header className="relative z-50" id="pb-mainheader">
			<div className="relative overflow-hidden">
				<Navbar className="sticky top-0 z-50" as="nav" role="navigation">
					<Navbar.Brand
						className={
							isTournament
								? '!bg-blue-600 before:!bg-blue-600'
								: isTeamLeagues
									? '!bg-[#EF612D] before:!bg-[#EF612D] !px-0 flex items-center justify-center'
									: ''
						}
						tag={Link}
						href={
							!baseOrigin && application !== 'clubs'
								? '/'
								: isTournament
									? tournamentsHref
									: isTeamLeagues
										? teamLeaguesHref
										: `${pickleballPrefix}${pickleballHref}`
						}
					>
						<h1>
							<img
								src={
									isTournament
										? `${getCDNURL(`${cdnUrl}/pickleball-app/static/images/pt-logo.svg`, 32, 32)}`
										: isTeamLeagues
											? `${getCDNURL(`${cdnUrl}/pickleballteamleagues-app/ptl-logo.svg`, 65, 40)}`
											: `${getCDNURL(`${cdnUrl}/pickleball-app/assets/images/logo.svg`, 32, 23)}`
								}
								width={isTournament ? 32 : isTeamLeagues ? 65 : 32}
								height={isTournament ? 32 : isTeamLeagues ? 40 : 23}
								alt={isTournament ? 'Pickleballtournaments.com' : 'Pickleball.com'}
								className="z-40 skew-x-12"
							/>
						</h1>
					</Navbar.Brand>
					<div className="mr-2 sm:mr-6" />

					<Nav
						baseOrigin={baseOrigin}
						application={application}
						pickleballHref={pickleballHref}
						tournamentsHref={tournamentsHref}
						clubsHref={clubsHref}
						teamLeaguesHref={teamLeaguesHref}
						leaguesHref={leaguesHref}
						navItems={navItems}
					/>

					<div className="ml-auto mr-1 flex">
						{user ? (
							<NavbarItem className="!inline-flex sm:!hidden">
								<UserProfile user={user} canAccessManage={canAccessManage} pickleballHref={pickleballHref} ssoLink={ssoLink} isLocalhost={isLocalHost} />
							</NavbarItem>
						) : (
							<NavbarItem className="!inline-flex sm:!hidden">
								<Link href={isLocalHost ? `/login?continue=${encodeURIComponent(baseURL)}` : `${ssoLink}/?flow=SelfService&continue=${encodeURIComponent(baseURL)}`} rel="nofollow">
									Login
								</Link>
							</NavbarItem>
						)}

						<div
							className={`!hidden -skew-x-12 items-center justify-center px-7 text-white sm:!inline-flex ${
								isTournament ? '!bg-blue-600' : isTeamLeagues ? '!bg-[#EF612D] before:!bg-[#EF612D]' : 'bg-experiment'
							}`}
							role="button"
							tabIndex={0}
							data-testid={`nav-pickleball-hub`}
						>
							<div className="skew-x-12">
								<Menu showArrow={false} placement="bottom" offset={24} width={172}>
									<Menu.Trigger asChild>
										<div className="flex cursor-pointer items-center gap-1.5">
											<span className="relative flex text-sm font-semibold text-white transition-all duration-150 hover:text-opacity-80">
												{BLOG_LABEL}
											</span>
											<FontAwesomeIcon icon={faChevronDown} size="sm" />
										</div>
									</Menu.Trigger>
									<Menu.List className="min-w-[172px] !max-w-[172px] !px-0 !py-2">
										{filteredCategories.map((category) => (
											<Menu.Item
												key={category.uuid}
												className="cursor-pointer rounded-lg bg-white text-sm font-medium text-gray-900 hover:bg-[#EBE9FE]"
											>
												<Link
													href={`${pickleballPrefix}${pickleballHref}/${category.slug}/all`}
													className={`line-clamp-1 w-full text-ellipsis px-4 py-2 !outline-none ${
														isBlogPathname && categorySlug === category.slug ? 'underline' : ''
													}`}
												>
													{category.title}
												</Link>
											</Menu.Item>
										))}
									</Menu.List>
								</Menu>
							</div>
						</div>

						<NavbarItem
							onClick={(e) => {
								e.stopPropagation();
								e.preventDefault();
								setSearchShown(!searchShown);
							}}
							className={`!px-3 sm:!px-4 ${isTournament && !searchShown ? 'sm:!border-blue-600' : isTeamLeagues && !searchShown ? 'sm:!border-[#EF612D]' : ''}
								${isTournament && searchShown ? '!border-blue-600 !bg-blue-600' : isTeamLeagues && searchShown ? '!border-[#EF612D] !bg-[#EF612D]' : ''}`}
							active={searchShown}
							ref={searchRef}
							tabIndex={0}
						>
							<FontAwesomeIcon
								className="sm:!hidden"
								icon={faSearch}
								aria-label="Search"
								title="Search Icon"
								role="button"
								titleId="search-icon-mobile"
							/>
							<FontAwesomeIcon
								className="!hidden sm:!flex"
								size="lg"
								icon={faSearch}
								aria-label="Search"
								title="Search Icon"
								role="button"
								titleId="search-icon-desktop"
							/>
						</NavbarItem>

						{user?.uuid && (
							<div className="flex w-[41px] items-center justify-center sm:hidden">
								<div className="inline-flex items-start sm:hidden">
									<div className="h-5 w-px -skew-x-12 bg-gray-700"></div>
								</div>
								<div className="inline-flex w-full items-center justify-center sm:hidden">
									<NotificationsPopupMobile notifications={notifications} checkoutLink={`${checkoutLink}/pending`} />
								</div>
							</div>
						)}

						<NavbarItem className="!inline-flex items-center !px-3 sm:!hidden" onClick={() => setMobileMenuOpened(!mobileMenuOpened)}>
							<FontAwesomeIcon icon={faBars} />
						</NavbarItem>
					</div>

					{mobileMenuOpened && (
						<div className="fixed inset-0 flex w-full overflow-hidden sm:hidden">
							<div
								aria-hidden="true"
								className="absolute inset-0 bg-white/30 backdrop-blur-md"
								onClick={() => setMobileMenuOpened(false)}
							/>
							<div
								aria-hidden="true"
								className={`absolute right-2 top-2 flex size-11 items-center justify-center rounded-md ${isTournament ? 'bg-blue-500' : 'bg-brand-700'}`}
								onClick={() => setMobileMenuOpened(false)}
							>
								<FontAwesomeIcon icon={faTimes} className="text-white" />
							</div>
							<div className="relative z-50 w-[46%] shrink-0 overflow-auto bg-gray-800">
								<ul className="flex flex-col divide-y divide-gray-900">
									<li className="list-none bg-white">
										<Link
											href={`${pickleballPrefix}${pickleballHref}/watch-now`}
											className="block px-6 py-4 text-sm font-bold leading-5 text-gray-900"
										>
											Watch
										</Link>
									</li>
									{navItems.map((item) => (
										<React.Fragment key={item.id}>
											{item.children === BLOG_LABEL && filteredCategories ? (
												<>
													{filteredCategories.map((category) => (
														<li key={category.uuid} className="list-none">
															<Link
																href={`${pickleballPrefix}${pickleballHref}/${category.slug}/all`}
																className="block px-6 py-4 text-sm font-bold leading-5 text-white"
															>
																{category.title}
															</Link>
														</li>
													))}
												</>
											) : (
												<li className="list-none">
													<Link href={item.href} className="block px-6 py-4 text-sm font-bold leading-5 text-white">
														{item.children}
													</Link>
												</li>
											)}
										</React.Fragment>
									))}
									{(isTournament || isTeamLeagues || isClubs) && (
										<ul className="flex flex-col divide-y divide-gray-900">
											<li className="list-none">
												<Link
													href={`${pickleballPrefix}${pickleballHref}`}
													className="block px-6 py-4 text-sm font-bold leading-5 text-white"
												>
													Pickleball.com
												</Link>
											</li>
										</ul>
									)}
								</ul>
							</div>
						</div>
					)}
				</Navbar>
			</div>
			{searchShown && (
				<GlobalSearch
					searchVisible={searchShown}
					globalSearchButtons={searchButtons}
					toggleRef={searchRef}
					onHide={() => setSearchShown(false)}
					isClubs={isClubs}
					pickleballHref={pickleballHref}
					tournamentsHref={tournamentsHref}
					clubsHref={clubsHref}
					teamLeaguesHref={teamLeaguesHref}
					leaguesHref={leaguesHref}
				/>
			)}
		</header>
	);
}
