'use client';

import clsx from 'clsx';
import parse from 'html-react-parser';
import Script from 'next/script';
import { useEffect, useRef, useState } from 'react';

import { AdType, ModalPlacementInterface } from '../utils/types';
import { getZoneImage } from '../utils/getZoneImage';
import { triggerURL } from '../utils/triggerURL';
import { AD_SIZES_HASH } from '../utils/constants';

// Regular expressions to match style, script, and text (html) in between
const STYLE_REGEX = /<style>(.*?)<\/style>/s; // 's' flag enables the dot (.) to match newline characters
const SCRIPT_REGEX = /<script>(.*?)<\/script>/s;
const BETWEEN_REGEX = /<\/style>(.*?)<script>/s;

// Function to extract matched substrings
const extractParts = (input: string): (string | undefined)[] => {
	if (!input) {
		return ['', '', ''];
	}

	const styleMatch = input.match(STYLE_REGEX);
	const scriptMatch = input.match(SCRIPT_REGEX);
	const htmlMatch = input.match(BETWEEN_REGEX);

	// Extracting matched substrings or returning empty string if not found
	const stylePart = styleMatch ? styleMatch[1] : '';
	const scriptPart = scriptMatch ? scriptMatch[1] : '';
	const htmlPart = htmlMatch ? htmlMatch[1] : '';

	return [stylePart, htmlPart, scriptPart];
};

interface ModalAdvertisementInfo {
	image?: ModalPlacementInterface;
	pageId: number;
	place: number;
	viewableURLCalled: boolean;
	eligibleURLCalled: boolean;
}

// This modal is supposed to show on all screen sizes, so there is no showFromWidth prop
export interface ModalAdvertisementProps {
	className?: string;
	height?: number;
	ipAddress?: string;
	pageId: number;
	place: number;
	zoneId: string;
	width?: number;
	isProd?: boolean;
}

const ModalAdvertisement = ({ className, height, ipAddress, pageId, place, zoneId, width, isProd }: ModalAdvertisementProps) => {
	const adRef = useRef<HTMLDivElement>(null);

	const placementId = `${zoneId}_${pageId}_${place}`;

	const [adInfo, setAdInfo] = useState<ModalAdvertisementInfo>({
		pageId: +pageId,
		place: +place,
		viewableURLCalled: false,
		eligibleURLCalled: false
	});

	const adSizeInfo = AD_SIZES_HASH[zoneId as AdType];
	const actualWidth = width ? width : adSizeInfo.width;
	const actualHeight = height ? height : adSizeInfo.height;
	const adSize = actualWidth && actualHeight ? `${actualWidth}x${actualHeight}` : '';

	useEffect(() => {
		if (isProd) {
			const fetchImage = async (): Promise<boolean> => {
				const currentUrl = location.protocol + '//' + location.host + location.pathname;
				const screenWidth = screen.width;
				const screenHeight = screen.height;
				const screenPixelRatio = window.devicePixelRatio;
				const userAgent = navigator.userAgent;

				const response = await getZoneImage({
					ipAddress: ipAddress,
					pageId: adInfo.pageId,
					place: adInfo.place,
					refferingURL: currentUrl,
					screenHeight: screenHeight,
					screenPixelRatio: screenPixelRatio,
					screenWidth: screenWidth,
					size: adSize,
					userAgent: userAgent,
					zoneId: zoneId
				});

				if (response.status === 200 && response.placement) {
					const fetchedImage: ModalPlacementInterface = response.placement;

					setAdInfo((prevAdInfo) => ({
						...prevAdInfo,
						image: fetchedImage,
						place: prevAdInfo.place + 1,
						viewableURLCalled: false,
						eligibleURLCalled: false
					}));

					return true; // ad zone image fetched successfully
				}

				return false; // ad zone image didn't fetch successfully
			};

			if (!adInfo.image) {
				fetchImage();
			}
		}

		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (isProd) {
			const observer = new IntersectionObserver(
				(entries) => {
					entries.forEach((entry) => {
						if (!adInfo.eligibleURLCalled) {
							if (adInfo.image?.eligible_url) {
								triggerURL(adInfo.image.eligible_url);
							}
							setAdInfo((prevAdInfo) => ({ ...prevAdInfo, eligibleURLCalled: true }));
						}

						// Check if 50% or more of the target element is visible
						if (entry.intersectionRatio >= 0.5 && !adInfo.viewableURLCalled) {
							if (adInfo.image?.viewable_url) {
								triggerURL(adInfo.image.viewable_url);
							}
							setAdInfo((prevAdInfo) => ({ ...prevAdInfo, viewableURLCalled: true }));
						}
					});
				},
				{ threshold: 0.5 } // Set threshold to 0.5 (50% visibility)
			);

			const adElement = adRef.current;

			if (adElement) {
				observer.observe(adElement);
			}

			return () => {
				if (adElement) {
					observer.unobserve(adElement);
				}
			};
		}

		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [adInfo.image, adInfo.viewableURLCalled, adInfo.eligibleURLCalled]);

	if (!adInfo.image) {
		return null;
	}

	const classes = clsx('max-w-full overflow-hidden rounded-xl', className);

	const inputString = adInfo.image.body;

	// Extracting parts from the input string
	// eslint-disable-next-line prefer-const
	let [stylesPart, htmlPart, scriptPart] = extractParts(inputString);

	if (scriptPart) {
		scriptPart = scriptPart?.trim();

		const textToRemoveFromStart = `console.count();`;
		const textToRemoveFromStart2 = `document.addEventListener('DOMContentLoaded', function () {`;
		const textToRemoveFromEnd = `});`;

		if (scriptPart.startsWith(textToRemoveFromStart)) {
			scriptPart = scriptPart.substring(textToRemoveFromStart.length);
		}

		scriptPart = scriptPart?.trim();

		if (scriptPart.startsWith(textToRemoveFromStart2)) {
			scriptPart = scriptPart.substring(textToRemoveFromStart2.length);
		}

		// Remove from the end
		if (scriptPart.endsWith(textToRemoveFromEnd)) {
			scriptPart = scriptPart.slice(0, -textToRemoveFromEnd.length);
		}
	}

	return (
		<div id={placementId} className={classes} ref={adRef}>
			<style>{stylesPart}</style>
			<Script id="adbutler-modal-script" strategy="afterInteractive">
				{scriptPart}
			</Script>
			<div>
				{adInfo.image.is_redirectable && htmlPart ? (
					<a href={adInfo.image.redirect_url} target={adInfo.image.target} rel="nofollow" aria-label={`${adInfo.image.alt_text} (opens in new tab)`}>
						{parse(htmlPart)}
					</a>
				) : htmlPart ? (
					<>{parse(htmlPart)}</>
				) : null}
				{adInfo.image.accupixel_url && <img src={adInfo.image.accupixel_url} alt="" className="max-w-full" />}
			</div>
		</div>
	);
};

export default ModalAdvertisement;
