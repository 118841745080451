const NEXT_PUBLIC_IMAGE_CDN = 'https://images.pickleball.com';
const NEW_NEXT_PUBLIC_IMAGE_CDN = 'https://cdn.pickleball.com';

export default function getCDNURL(url: string, width: number, height: number, saturation?: number, faceCrop?: boolean): string {
	if (url && url.startsWith(NEXT_PUBLIC_IMAGE_CDN)) {
		const basePath = url.replace(NEXT_PUBLIC_IMAGE_CDN, NEW_NEXT_PUBLIC_IMAGE_CDN);
		const sizeQuery =
			width && height
				? `?width=${width}&height=${height}&optimizer=image${saturation ? `&saturation=${saturation}` : ``}${faceCrop ? `&face_crop=${faceCrop}` : ``}`
				: '';

		return `${basePath}${sizeQuery}`;
	}

	return url;
}
