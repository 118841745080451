import getCDNURL from '@pickleballinc/ui/components/pickleball-app/utils/getCDNURL';

import { NavLink } from '@/modules/wpr-landing/wprTypes';

export const WPRLinksData: NavLink[] = [
	{ label: 'WPR', hash: '#wpr', isTitle: true },
	{ label: 'What is WPR', hash: '#what-is-wpr' },
	{ label: 'Key Features', hash: '#key-features' },
	{ label: 'Benefits for Players', hash: '#benefits-players' },
	{ label: 'Benefits for Community', hash: '#benefits-community' },
	{ label: 'Explore Ranking', hash: '#explore-ranking' }
];

export const WPRKeyFeatures = [
	{
		imageSrc: getCDNURL(`${process.env.NEXT_PUBLIC_IMAGE_CDN}/pickleball-app/assets/images/wpr/purple_ball.svg`, 32, 32),
		imageAlt: 'Purple ball',
		title: 'More than 300,000 amateurs in 105 countries',
		description:
			'Leveraging extensive data from tournaments managed on pickleballtournaments.com, the rankings reflect actual player performance in a wide range of competitive events.'
	},
	{
		imageSrc: getCDNURL(`${process.env.NEXT_PUBLIC_IMAGE_CDN}/pickleball-app/assets/images/wpr/orange_ball.svg`, 32, 32),
		imageAlt: 'Orange ball',
		title: 'Natively integrated with top Pickleball platforms',
		description:
			'The rankings encompass various categories, including singles, doubles, and mixed doubles, ensuring a fair assessment for players of all styles and preferences.'
	},
	{
		imageSrc: getCDNURL(`${process.env.NEXT_PUBLIC_IMAGE_CDN}/pickleball-app/assets/images/wpr/blue_ball.svg`, 32, 32),
		imageAlt: 'Blue ball',
		title: 'Recognized as the best by top pickleball leaders',
		description:
			'The rankings are continually updated to reflect the latest tournament results, providing a real-time snapshot of the competitive landscape.'
	},
	{
		imageSrc: getCDNURL(`${process.env.NEXT_PUBLIC_IMAGE_CDN}/pickleball-app/assets/images/wpr/pink_ball.svg`, 32, 32),
		imageAlt: 'Pink ball',
		title: 'Powered by a Transparent Algorithms and helpful team',
		description: 'The ranking methodology is clearly defined and accessible, allowing players to understand how their ranking is calculated.'
	}
];
