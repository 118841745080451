'use client';

interface EnvironmentBarProps {
	text?: string;
	className?: string;
}

const EnvironmentBar: React.FC<EnvironmentBarProps> = ({ text = 'DEVELOPMENT', className = '' }) => {
	return (
		<div className={`flex w-full justify-center bg-error-600 ${className}`}>
			<div className="flex w-full justify-center">
				<p className="text-sm text-white">{text}</p>
			</div>
		</div>
	);
};

export default EnvironmentBar;
