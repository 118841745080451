'use client';

import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dialog, DialogContent } from '@pickleballinc/ui/components/ui/dialog';
import { useEffect, useState } from 'react';

import { ModalSSO } from '@/components/ModalSSO';
import { useModalSSO } from '@/lib/modal-sso';

interface SSOMessage {
	url: string;
}

interface SSOInterface {
	message: string; // stringified SSOMessage
}

export const ModalSSODialog = () => {
	const { active, setActive } = useModalSSO();
	const [iframeParentBaseURL, setIframeParentBaseURL] = useState('');

	const isLocalhost = process.env.NEXT_PUBLIC_REWRITE_DOMAIN_NAME?.startsWith('localhost');

	useEffect(() => {
		setIframeParentBaseURL(window.location.origin);

		// listen for window post message and you will get back url, just redirect user to that url
		const handle = (event: MessageEvent<any>) => {
			if (event.origin?.trim() === process.env.NEXT_PUBLIC_SSO_ENDPOINT) {
				if (event.data) {
					const data: SSOInterface = event.data;
					console.log('data', data);

					const ssoMessage: SSOMessage = JSON.parse(data.message);
					console.log('ssoMessage', ssoMessage);

					if (isLocalhost) {
						window.location.reload();
					} else {
						window.location.href = `${ssoMessage.url}?continue=${encodeURI(window.location.href)}`;
					}
				}
			}
		};

		window.addEventListener('message', handle);

		return () => {
			window.removeEventListener('message', handle);
		};
	}, []);

	return (
		<Dialog open={active}>
			<DialogContent
				className="shadow-none size-full max-h-[1000px] w-full max-w-[568px] overflow-hidden border-0 !bg-transparent px-4 md:px-0"
				hideClose
			>
				<div className="absolute right-[36px] top-[56px] z-20 cursor-pointer md:right-[24px] md:top-[148px]" onClick={() => setActive(false)}>
					<FontAwesomeIcon icon={faTimes} className="size-4 text-white" />
				</div>
				<div className="overflow-hidden rounded-t-xl">
					<ModalSSO iframeParentBaseURL={iframeParentBaseURL} />
				</div>
			</DialogContent>
		</Dialog>
	);
};
