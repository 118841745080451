'use client';
import getCDNURL from '@pickleballinc/ui/components/pickleball-app/utils/getCDNURL';
import CustomLink from '@pickleballinc/ui/components/ui/custom-link';

import { WPRLinksData } from '@/modules/wpr-landing/wprConstants';

const WPRFooter = () => {
	const baseUrl = process.env.NEXT_PUBLIC_REWRITE_DOMAIN_NAME;
	const isLocalhost = baseUrl?.startsWith('localhost');
	const url = `http${isLocalhost ? '' : 's'}://${baseUrl}`;

	return (
		<div className="overflow-hidden bg-white lg:h-[400px]">
			<div className="mx-auto pt-24 lg:w-[1216px]">
				<div className="flex justify-center">
					<img
						src={getCDNURL(`${process.env.NEXT_PUBLIC_IMAGE_CDN}/pickleball-app/assets/images/logo-full.svg`, 256, 48)}
						alt="Pickleball Logo Full"
						className="h-12 w-64"
					/>
				</div>
				<ul className="mb-16 mt-8 flex w-full flex-col items-center justify-center gap-y-3 lg:flex-row lg:gap-x-8 lg:gap-y-0">
					{WPRLinksData.filter((link) => !link.isTitle).map(({ label, hash }) => (
						<li key={hash}>
							<CustomLink
								href={`${process.env.NEXT_PUBLIC_WPR_URL}${hash}`}
								className="font-semibold text-gray-600 transition-colors hover:text-gray-900"
							>
								{label}
							</CustomLink>
						</li>
					))}
				</ul>
				<div className="h-px w-full bg-gray-200" />
				<div className="mb-20 mt-6 flex w-full flex-col items-center justify-center px-4 lg:mb-0 lg:mt-6 lg:flex-row lg:justify-between lg:px-0">
					<p className="text-gray-500">World Pickleball Rankings {new Date().getFullYear()}</p>
					<div className="mt-4 flex whitespace-nowrap text-gray-600 lg:mt-0">
						<CustomLink href={`${url}/privacy-policy`}>Privacy Policy</CustomLink>
						<span className="mx-2">|</span>
						<CustomLink href={`${url}/terms-of-use`}>Terms of Use</CustomLink>
						<span className="mx-2">|</span>
						<CustomLink href={`${url}/dmca-notice`}>DMCA Notice</CustomLink>
					</div>
				</div>
			</div>
		</div>
	);
};

export default WPRFooter;
