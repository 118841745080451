'use client';

import { AD_SIZES_HASH } from '../utils/constants';
import { AdType } from '../utils/types';
import clsx from 'clsx';
import { useRef } from 'react';
import Advertisement from './advertisement';

export interface AdvertisementWrapperProps {
	containerClassname?: string;
	className?: string;
	height?: number;
	ipAddress?: string;
	pageId: number;
	place: number;
	zoneId: string;
	width?: number;
	isProd?: boolean;
	showFromWidth?: number;
	hideFromWidth?: number;
	numberOfDifferentZoneImages?: number;
	setNumberOfDifferentZoneImages?: React.Dispatch<React.SetStateAction<number | undefined>>;
}

const AdvertisementWrapper = (props: AdvertisementWrapperProps) => {
	const adRef = useRef<HTMLDivElement | null>(null);

	const { containerClassname, className, height, pageId, place, zoneId, width, isProd, showFromWidth = 0 } = props;

	const placementId = `${zoneId}_${pageId}_${place}`;

	const adSizeInfo = AD_SIZES_HASH[zoneId as AdType];
	const actualWidth = width ? width : adSizeInfo.width;
	const actualHeight = height ? height : adSizeInfo.height;

	const aspectRatio = actualWidth / actualHeight;

	const classes = clsx(
		'relative h-0 w-full overflow-hidden rounded-md sm:rounded-xl',
		{
			'border border-solid bg-gray-400 text-white': !isProd
		},
		className
	);

	return (
		<div className={containerClassname} style={{ maxWidth: `${actualWidth}px` }}>
			<div id={placementId} ref={adRef} style={{ paddingTop: `calc(100% / ${aspectRatio})` }} className={classes}>
				<Advertisement {...props} showFromWidth={showFromWidth} adRef={adRef} />
			</div>
		</div>
	);
};

export default AdvertisementWrapper;
