import { faCheck, faClipboard } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';

interface ClipboardIconProps {
	content: string;
}

const copyOperation = (text: string) => {
	navigator.clipboard.writeText(text);
};

const ClipboardIcon = ({ content }: ClipboardIconProps) => {
	const [copiedURL, setCopiedURL] = useState<boolean>(false);

	useEffect(() => {
		const timeout = setTimeout(() => {
			if (copiedURL) setCopiedURL(false);
		}, 1000);

		return () => clearTimeout(timeout);
	}, [copiedURL]);

	return (
		<FontAwesomeIcon
			icon={copiedURL ? faCheck : faClipboard}
			className="cursor-pointer text-white"
			onClick={() => {
				copyOperation(content);
				setCopiedURL(true);
			}}
			size={copiedURL ? 'xs' : 'sm'}
		/>
	);
};

export default ClipboardIcon;
