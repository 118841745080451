const SkipIcon = () => {
	return (
		<svg
			className="inline-block size-4 sm:size-5"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			aria-hidden="true"
			focusable="false"
			width="1em"
			height="1em"
			preserveAspectRatio="xMidYMid meet"
			viewBox="0 0 24 24"
			fill="white"
		>
			<path d="M16 18h2V6h-2M6 18l8.5-6L6 6v12z"></path>
		</svg>
	);
};

export default SkipIcon;
