import { BLOG_LABEL } from './constants';

export type Application = 'pickleball' | 'tournament' | 'clubs' | 'team-leagues';

export interface NavItem {
	id: string;
	children: string;
	target?: string;
	suffix?: string;
	href: string;
}

interface NavItemsProps {
	isLocalHost: boolean;
	application: Application;
	pickleballHref: string;
	tournamentsHref: string;
	clubsHref: string;
	teamLeaguesHref: string | undefined;
	leaguesHref: string;
}

export const getNavItems = ({
	isLocalHost,
	application,
	pickleballHref,
	tournamentsHref,
	clubsHref,
	teamLeaguesHref,
	leaguesHref
}: NavItemsProps): NavItem[] => {
	const prefix = `http${isLocalHost ? '' : 's'}://`;

	const pickleballNav = [
		{
			id: '1',
			children: 'News',
			href: `${prefix}${pickleballHref}/news/all`,
			suffix: '/news'
		},
		{
			id: '2',
			children: 'Tournaments',
			href: tournamentsHref
		},
		{
			id: '3',
			children: 'Results',
			href: `${prefix}${pickleballHref}/results`,
			suffix: '/results'
		},
		{
			id: '4',
			children: 'Leagues',
			href: isLocalHost ? '/leagues' : leaguesHref,
			suffix: '/leagues'
		},
		...(teamLeaguesHref
			? [
					{
						id: '5',
						children: 'Team Leagues',
						href: teamLeaguesHref
					}
				]
			: []),
		{
			id: '6',
			children: 'Clubs',
			href: clubsHref
		},
		{
			id: '7',
			children: 'Players',
			href: `${prefix}${pickleballHref}/players`,
			suffix: '/players'
		},
		{
			id: '8',
			children: 'Rankings',
			href: `${prefix}${pickleballHref}/rankings`,
			suffix: '/rankings'
		},
		{
			id: '9',
			children: 'Tickets',
			target: '_blank',
			href: 'https://www.tixr.com/groups/ppa'
		},
		{
			id: '10',
			children: BLOG_LABEL,
			href: `${prefix}${pickleballHref}/news/all`
		}
	];

	const nonPickleballNav = [
		{
			id: '1',
			children: 'News',
			href: `https://${pickleballHref}/news/all`
		},
		{
			id: '2',
			children: 'Tournaments',
			href: tournamentsHref,
			suffix: application === 'tournament' ? '/tournaments' : undefined
		},
		{
			id: '3',
			children: 'Results',
			href: `https://${pickleballHref}/results`
		},
		{
			id: '4',
			children: 'Leagues',
			href: leaguesHref
		},
		...(teamLeaguesHref
			? [
					{
						id: '5',
						children: 'Team Leagues',
						href: teamLeaguesHref,
						suffix: application === 'team-leagues' ? '/team-leagues' : undefined
					}
				]
			: []),
		{
			id: '6',
			children: 'Clubs',
			href: clubsHref,
			suffix: application === 'clubs' ? '/clubs' : undefined
		},
		{
			id: '7',
			children: 'Players',
			href: `https://${pickleballHref}/players`
		},
		{
			id: '8',
			children: 'Rankings',
			href: `https://${pickleballHref}/rankings`
		},
		{
			id: '9',
			children: 'Tickets',
			target: '_blank',
			href: 'https://www.tixr.com/groups/ppa'
		},
		{
			id: '10',
			children: BLOG_LABEL,
			href: `https://${pickleballHref}/news/all`
		}
	];

	return application === 'pickleball'
		? pickleballNav
		: application === 'tournament' || application === 'clubs' || application === 'team-leagues'
			? nonPickleballNav
			: [];
};
