'use client';

import { getZoneImage } from '../utils/getZoneImage';
import { AD_SIZES_HASH, ADVERTISEMENT_TEXT_PLACEHOLDER } from '../utils/constants';
import { AdType, PlacementInterface } from '../utils/types';
import { MutableRefObject, useEffect, useState } from 'react';
import { triggerURL } from '../utils/triggerURL';

interface AdvertisementInfo {
	image?: PlacementInterface;
	pageId: number;
	place: number;
	viewableURLCalled: boolean;
	eligibleURLCalled: boolean;
}

interface AdvertisementProps {
	ipAddress?: string;
	pageId: number;
	place: number;
	zoneId: string;
	isProd?: boolean;
	showFromWidth: number;
	hideFromWidth?: number;
	numberOfDifferentZoneImages?: number;
	setNumberOfDifferentZoneImages?: React.Dispatch<React.SetStateAction<number | undefined>>;
	adRef: MutableRefObject<HTMLDivElement | null>;
}

// https://www.adbutler.com/help/article/adbutler-viewability#what-the-viewability-metrics-mean
const Advertisement = ({
	ipAddress,
	pageId,
	place,
	zoneId,
	isProd = false,
	showFromWidth,
	hideFromWidth,
	numberOfDifferentZoneImages,
	setNumberOfDifferentZoneImages,
	adRef
}: AdvertisementProps) => {
	const [adInfo, setAdInfo] = useState<AdvertisementInfo>({
		pageId: numberOfDifferentZoneImages ? +pageId + Math.floor(+place / numberOfDifferentZoneImages) : +pageId,
		place: numberOfDifferentZoneImages ? +place % numberOfDifferentZoneImages : +place,
		viewableURLCalled: false,
		eligibleURLCalled: false
	});

	const adSizeInfo = AD_SIZES_HASH[zoneId as AdType].size || '0x0';

	useEffect(() => {
		if (isProd && window.screen.width >= showFromWidth && (!hideFromWidth || window.screen.width < hideFromWidth)) {
			const fetchImage = async (): Promise<boolean> => {
				const currentUrl = location.protocol + '//' + location.host + location.pathname;
				const screenWidth = screen.width;
				const screenHeight = screen.height;
				const screenPixelRatio = window.devicePixelRatio;
				const userAgent = navigator.userAgent;

				const response = await getZoneImage({
					ipAddress: ipAddress,
					pageId: adInfo.pageId,
					place: adInfo.place,
					refferingURL: currentUrl,
					screenHeight: screenHeight,
					screenPixelRatio: screenPixelRatio,
					screenWidth: screenWidth,
					size: adSizeInfo,
					userAgent: userAgent,
					zoneId: zoneId
				});

				if (response.status === 200 && response.placement) {
					const fetchedImage: PlacementInterface = response.placement;

					setAdInfo((prevAdInfo) => ({
						...prevAdInfo,
						image: fetchedImage,
						place: prevAdInfo.place + 1,
						viewableURLCalled: false,
						eligibleURLCalled: false
					}));

					// Trigger eligible impressions if the tab is switched since ad image is still rendered
					if (document.visibilityState !== 'visible' && fetchedImage.eligible_url) {
						triggerURL(fetchedImage.eligible_url);
						setAdInfo((prevAdInfo) => ({ ...prevAdInfo, eligibleURLCalled: true }));
					}
				} else {
					if (setNumberOfDifferentZoneImages) {
						setNumberOfDifferentZoneImages(adInfo.place);
					}

					const response2 = await getZoneImage({
						// generating new pageId and fetching from place 0
						ipAddress: ipAddress,
						pageId: adInfo.pageId + 1,
						place: 0,
						refferingURL: currentUrl,
						screenHeight: screenHeight,
						screenPixelRatio: screenPixelRatio,
						screenWidth: screenWidth,
						size: adSizeInfo,
						userAgent: userAgent,
						zoneId: zoneId
					});

					if (response2.status === 200 && response2.placement) {
						const fetchedImage2: PlacementInterface = response2.placement;
						setAdInfo((prevAdInfo) => ({
							...prevAdInfo,
							image: fetchedImage2,
							pageId: prevAdInfo.pageId + 1,
							place: 1,
							viewableURLCalled: false,
							eligibleURLCalled: false
						}));

						// Trigger eligible impressions if the tab is switched since ad image is still rendered
						if (document.visibilityState !== 'visible' && fetchedImage2.eligible_url) {
							triggerURL(fetchedImage2.eligible_url);
							setAdInfo((prevAdInfo) => ({ ...prevAdInfo, eligibleURLCalled: true }));
						}
					} else {
						return false; // ad zone image fetch error - could be that AdButler API is down or the device browser has an advertisement blocking system
					}
				}

				return true; // ad zone image fetched successfully
			};

			if (adInfo.image && adInfo.image.refresh_time) {
				const interval = setInterval(async () => {
					const fetchedSuccessfuly = await fetchImage();
					if (!fetchedSuccessfuly) {
						clearInterval(interval);
					}
				}, +adInfo.image.refresh_time * 1000);

				return () => clearInterval(interval);
			} else {
				fetchImage();
			}
		}

		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [adInfo.image]);

	useEffect(() => {
		if (isProd && window.screen.width >= showFromWidth && (!hideFromWidth || window.screen.width < hideFromWidth)) {
			const observer = new IntersectionObserver(
				(entries) => {
					entries.forEach((entry) => {
						if (!adInfo.eligibleURLCalled) {
							if (adInfo.image?.eligible_url) {
								triggerURL(adInfo.image.eligible_url);
							}
							setAdInfo((prevAdInfo) => ({ ...prevAdInfo, eligibleURLCalled: true }));
						}

						// Check if 50% or more of the target element is visible
						if (entry.intersectionRatio >= 0.5 && !adInfo.viewableURLCalled) {
							if (adInfo.image?.viewable_url) {
								triggerURL(adInfo.image.viewable_url);
							}
							setAdInfo((prevAdInfo) => ({ ...prevAdInfo, viewableURLCalled: true }));
						}
					});
				},
				{ threshold: 0.5 } // Set threshold to 0.5 (50% visibility)
			);

			const adElement = adRef.current;

			if (adElement) {
				observer.observe(adElement);
			}

			return () => {
				if (adElement) {
					observer.unobserve(adElement);
				}
			};
		}

		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [adInfo.image, adInfo.viewableURLCalled, adInfo.eligibleURLCalled]);

	if (isProd && !adInfo.image) {
		return null;
	}

	return (
		<>
			{isProd && adInfo.image ? (
				<>
					{adInfo.image.is_redirectable ? (
						<a
							href={adInfo.image.redirect_url}
							target={adInfo.image.target}
							rel="nofollow"
							className="absolute inset-0 size-full object-cover"
							aria-label={`${adInfo.image.alt_text} (opens in new tab)`}
						>
							<img src={adInfo.image.image_url} alt={adInfo.image.alt_text} />
						</a>
					) : (
						<img src={adInfo.image.image_url} alt={adInfo.image.alt_text} className="absolute inset-0 size-full object-cover" />
					)}
					{adInfo.image.accupixel_url && <img src={adInfo.image.accupixel_url} alt="" />}
				</>
			) : (
				<div className="absolute inset-0 flex size-full items-center justify-center object-cover">{ADVERTISEMENT_TEXT_PLACEHOLDER}</div>
			)}
		</>
	);
};

export default Advertisement;
