'use client';

import { createContext, ReactNode, useContext } from 'react';

import { AppConfig } from '@/contexts/applicationConfig/types';

interface AppConfigContextProps {
	config: AppConfig;
}

const AppConfigContext = createContext<AppConfigContextProps | undefined>(undefined);

interface AppConfigProviderProps {
	children: ReactNode;
	config: AppConfig;
}

export const AppConfigProvider = ({ children, config }: AppConfigProviderProps) => {
	return <AppConfigContext.Provider value={{ config }}>{children}</AppConfigContext.Provider>;
};

export const useAppConfig = () => {
	const context = useContext(AppConfigContext);
	if (!context) {
		throw new Error('useAppConfig must be used within an AppConfigProvider');
	}
	return context;
};
