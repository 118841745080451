'use client';

import { faMoneyBillWave, faUserXmark } from '@fortawesome/pro-light-svg-icons';
import { faBell, faXmark } from '@fortawesome/pro-regular-svg-icons';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Drawer, NavbarItem } from '@pickleballinc/react-ui';
import { useEffect, useState } from 'react';

import { NotificationLookup } from './utils/types';

type NotificationsMobilePopupProps = {
	notifications: NotificationLookup | undefined;
	checkoutLink: string;
};

const NotificationsPopupMobile = ({ notifications, checkoutLink }: NotificationsMobilePopupProps) => {
	const [isDrawerOpen, setIsDrawerOpen] = useState(false);

	const toggleDrawer = () => {
		setIsDrawerOpen(!isDrawerOpen);
	};

	const trueCount = Object.values(notifications || {}).filter((value) => value === true).length;

	useEffect(() => {
		document.body.style.overflow = isDrawerOpen ? 'hidden' : '';
		return () => {
			document.body.style.overflow = '';
		};
	}, [isDrawerOpen]);

	return (
		<div className="sm:hidden">
			<NavbarItem onClick={toggleDrawer} className="flex items-center justify-center text-white" data-testid="notifications-button">
				<div className="relative">
					<FontAwesomeIcon icon={faBell} />
					{trueCount > 0 && (
						<div className="absolute -right-2 -top-1 flex size-4 items-center justify-center rounded-full bg-error-600">
							<p className="text-[10px] text-white">{trueCount}</p>
						</div>
					)}
				</div>
			</NavbarItem>

			<Drawer open={isDrawerOpen} onBackdropClick={toggleDrawer} wrapperClassname="h-screen !w-full overflow-auto">
				<div className="relative flex h-full flex-col justify-start bg-gray-100 p-1">
					<div className="mt-4 space-y-4 rounded-xl bg-white shadow-sm">
						<div className="flex h-[68px] w-full flex-row items-center justify-between border-b">
							<div className="flex h-full flex-row items-center justify-start gap-2 px-4">
								<h2 className="text-lg font-semibold text-gray-900">Notifications</h2>
							</div>
							<div onClick={toggleDrawer} className="flex size-8 items-center text-base text-black">
								<FontAwesomeIcon icon={faXmark} className="size-4" />
							</div>
						</div>
						{notifications?.isBalanceDue || notifications?.isWaiverDue ? (
							<>
								{notifications?.isBalanceDue && (
									<a href={`${checkoutLink}`} data-testid="notifications-link-balance-due">
										<div className="m-4 flex items-center gap-4 hover:bg-gray-100">
											<div className="flex size-12 items-center justify-center rounded-full border">
												<FontAwesomeIcon icon={faMoneyBillWave} className="text-brand-600" />
											</div>

											<p className="flex grow items-center text-sm font-medium text-gray-700">You have balance(s) due</p>
											<div className="flex h-full w-2 items-center">
												<FontAwesomeIcon icon={faCircle} className="size-2 pr-4 text-error-600" />
											</div>
										</div>
									</a>
								)}

								{notifications?.isWaiverDue && (
									<a href={`${checkoutLink}`} data-testid="notifications-link-open-waivers">
										<div className="m-4 flex items-center gap-4 hover:bg-gray-100">
											<div className="flex size-12 items-center justify-center rounded-full border">
												<FontAwesomeIcon icon={faUserXmark} className="text-brand-600" />
											</div>

											<p className="flex grow items-center text-sm font-medium text-gray-700">You have open waiver(s)</p>
											<div className="flex h-full w-2 items-center">
												<FontAwesomeIcon icon={faCircle} className="size-2 text-error-600" />
											</div>
										</div>
									</a>
								)}
							</>
						) : (
							<div className="m-4 flex items-center gap-4 pb-4">
								<p className="flex grow items-center text-sm font-medium text-gray-700">No notifications</p>
							</div>
						)}
					</div>
				</div>
			</Drawer>
		</div>
	);
};

export default NotificationsPopupMobile;
